import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  PfActionButtonComponent,
  PfCardComponent,
  PfFormSummaryEditionWrapperComponent,
} from 'pf-ui';
import { StatisticsService } from '@services/statistics.service';
import { TableModule } from 'primeng/table';
import { PluralizePipe, PluralizePipeModule } from '@app/pipes/pluralize.pipe';
import { RouterLink } from '@angular/router';
import { AdminRoute, RouteLabel } from '@utils/routes';
import { SkeletonModule } from 'primeng/skeleton';
import { CategoryStats } from '@models/statistics/statistics.model';
import { ChartModule } from 'primeng/chart';
import { ButtonModule } from 'primeng/button';

const MAX_DATASETS_AVAILABLE: number = 3;

@Component({
  selector: 'app-user-exam-by-category',
  standalone: true,
  imports: [
    CommonModule,
    PfCardComponent,
    TableModule,
    PluralizePipeModule,
    PfFormSummaryEditionWrapperComponent,
    RouterLink,
    SkeletonModule,
    ChartModule,
    ButtonModule,
    PfActionButtonComponent,
  ],
  templateUrl: './user-exam-by-category.component.html',
})
export class UserExamByCategoryComponent {
  protected readonly AdminRoute = AdminRoute;
  protected readonly RouteLabel = RouteLabel;

  pipe = new PluralizePipe();
  loading: boolean = false;
  chartIsDisplayed: boolean = false;
  otherDatasets: CategoryStats[] = [];

  userExamSessionFinisherByCategory: CategoryStats[] = Array.from({
    length: 3,
  }).map(() => {
    return {
      categoryName: '',
      userExamSessionCount: 0,
      successRate: 0,
    };
  });

  data: chartData = { datasets: [{ data: [] }], labels: [] };

  options: chartOptions = {
    cutout: '50%',
    plugins: {
      legend: {
        position: 'right',
      },
      tooltip: {
        callbacks: {
          label: (): string => {
            return ' ';
          },
          afterBody: (contexts: chartContextType[]): string[] =>
            this.getLabelTooltipFor(contexts[0]),
        },
      },
    },
  };

  constructor(private statisticsService: StatisticsService) {
    this.loading = true;
    this.statisticsService
      .getUserExamSessionFinisherByCategory()
      .subscribe((val) => {
        this.userExamSessionFinisherByCategory = val;
        this.initializeDoughnutsData(
          val.sort((a, b) => b.userExamSessionCount - a.userExamSessionCount),
        );
        this.loading = false;
      });
  }

  initializeDoughnutsData(
    userExamSessionsFinisherByCategory: CategoryStats[],
  ): void {
    this.chartIsDisplayed = userExamSessionsFinisherByCategory.length !== 0;

    const documentStyle = getComputedStyle(document.documentElement);

    const datasets: [
      {
        backgroundColor?: string[];
        data: number[];
        hoverBackgroundColor?: string[];
      },
    ] = [
      {
        data: [],
        backgroundColor: [
          documentStyle.getPropertyValue('--blue-500'),
          documentStyle.getPropertyValue('--yellow-500'),
          documentStyle.getPropertyValue('--green-500'),
          documentStyle.getPropertyValue('--gray-400'),
        ],
        hoverBackgroundColor: [
          documentStyle.getPropertyValue('--blue-400'),
          documentStyle.getPropertyValue('--yellow-400'),
          documentStyle.getPropertyValue('--green-400'),
          documentStyle.getPropertyValue('--gray-300'),
        ],
      },
    ];

    let otherDatasets: number = 0;

    userExamSessionsFinisherByCategory.forEach(
      (userExamSessionFinisherByCategory, index) => {
        if (index < MAX_DATASETS_AVAILABLE) {
          this.data.labels.push(userExamSessionFinisherByCategory.categoryName);
          datasets[0].data.push(
            userExamSessionFinisherByCategory.userExamSessionCount,
          );
        } else if (userExamSessionFinisherByCategory.userExamSessionCount > 0) {
          this.otherDatasets.push(userExamSessionFinisherByCategory);
          otherDatasets +=
            userExamSessionFinisherByCategory.userExamSessionCount;
        }
      },
    );

    if (this.otherDatasets.length > 0) {
      this.data.labels.push('Autres');
      datasets[0].data.push(otherDatasets);
    }
    this.data.datasets = datasets;
  }

  getLabelTooltipFor(context: chartContextType): string[] {
    if (context.dataIndex === 3) {
      return this.getMultipleLabelTooltipFor(this.otherDatasets);
    }
    return [this.pipe.transform(context.raw, 'candidat')];
  }

  getMultipleLabelTooltipFor(datasets: CategoryStats[]): string[] {
    return datasets.reduce((multiStringText: string[], dataset) => {
      multiStringText.push(
        `${dataset.categoryName}: ${this.pipe.transform(dataset.userExamSessionCount, 'candidat')}`,
      );
      return multiStringText;
    }, []);
  }

  get statsCategoriesRoute(): string {
    return `/${AdminRoute.StatsCategories}`;
  }
}

// DEFINITION OF TYPE
export type chartContextType = {
  formattedValue: string;
  raw: number;
  dataIndex: number;
  dataset: { label: string };
};

export type chartData = {
  datasets: {
    backgroundColor?: string[];
    data: number[];
    hoverBackgroundColor?: string[];
  }[];
  labels: string[];
};

export type chartOptions = {
  cutout: string;
  plugins: {
    legend: {
      position: string;
    };
    tooltip: {
      callbacks: {
        label: () => string;
        afterBody: (contexts: chartContextType[]) => string[];
      };
    };
  };
};
