<div
  class="section section-stats h-full flex flex-column justify-content-between"
>
  <div class="flex align-items-center gap-2 mb-4">
    <div
      class="flex border-round-xl w-2rem h-2rem bg-blue-gray-200 text-primary p-1 justify-content-center align-items-center"
    >
      <i class="pi pi-check-circle"></i>
    </div>
    <h3 class="my-0 text-xl font-medium">Catégories d’examens</h3>
  </div>

  @if (loading) {
    <div class="flex align-items-center">
      <p-skeleton shape="circle" size="8rem" styleClass="ml-6"></p-skeleton>
      <p-skeleton height="6rem" width="14rem" styleClass="ml-6"></p-skeleton>
    </div>
  } @else if (chartIsDisplayed) {
    <p-chart
      height="150px"
      *ngIf="!loading"
      type="doughnut"
      [data]="data"
      [options]="options"
      style="width: 100%"
    ></p-chart>
  } @else {
    <div class="h-full font-light text-gray-500">Aucune donnée</div>
  }

  <div class="flex justify-content-end mt-4">
    <pf-action-button
      type="previous"
      customIconClass=" "
      styleClass="p-button-tertiary border-none text-sm justify-content-start"
      label="Voir toutes les catégories"
      [routerLink]="statsCategoriesRoute"
    ></pf-action-button>
  </div>
</div>
